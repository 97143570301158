import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ProjectProfile } from '@ceres/domain';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ProjectRelationsSelectComponent } from '../../../project/components/project-relations/project-relations-select/project-relations-select.component';

@Component({
    selector: 'ceres-project-picker',
    templateUrl: './project-picker.component.html',
    styleUrls: ['./project-picker.component.scss']
})
export class ProjectPickerComponent implements OnDestroy {
    @Input() multiple = false;
    @Input() required = false;
    @Input() placeholderTranslationKey: string;
    @Input() projects: ProjectProfile[] = [];
    @Input() readonly: boolean;
    @Output() selected = new EventEmitter<ProjectProfile[]>();
    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private dialog: MatDialog) {}

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    protected openProjectSelection(): void {
        const dialogRef: MatDialogRef<any> = this.dialog.open(ProjectRelationsSelectComponent, {
            disableClose: true,
            width: '1000px',

            data: {
                multiple: this.multiple,
                parentId: null,
                projectTreeIds: []
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                tap((projects: ProjectProfile[] | ProjectProfile) => {
                    if (Array.isArray(projects)) {
                        this.projects = [...projects];
                    } else if (projects) {
                        this.projects = [projects];
                    }
                    this.selected.emit(this.projects);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    protected removeSelected(projectProfile?: ProjectProfile): void {
        if (projectProfile) {
            this.projects = this.projects.filter((project) => project.id !== projectProfile.id);
        } else {
            this.projects = [];
        }
        this.selected.emit(this.projects);
    }

    protected getProjectInputValue(): string {
        return this.projects.map((project) => project.mpTitle).join(', ');
    }
}
