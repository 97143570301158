import { Activity } from './activity';
import { BusinessArea } from './business-area';
import { BusinessPartner } from './business-partner';
import { Contract } from './contract';
import { Document } from './document';
import { AccountingEmployee, Employee } from './employee';
import { Location } from './location';
import { MaterialMaster } from './material-master';
import { Program } from './program';
import { AccountingElement } from './project-accounting-element';
import { Technology } from './technology';
import { AccountingInfos } from './project-accounting-infos';
import { PspElement } from './psp-element';
import { ProjectType } from './project-type';

export interface AccountingData {
    Id: number;
    AbgerechneterWert: number;
    Abzurechnen: number;
    Anmerkung: string;
    AnmerkungKFM: string;
    Auftragsbestand: number;
    Auftragswert: number;
    Bestellnummer: string;
    bestellnummerText: string;
    bestellNummerExistent: boolean;
    Bestellposition: string;
    FreigabeKfm: boolean;
    FreigabePL: boolean;
    FSGGesamtprojekt: number;
    FSGGesamtprojektC: number;
    Istkosten: number;
    Istpreis: number;
    KostenstelleGMS: string;
    Kundenart: string;
    LeistungszeitraumBis: Date;
    LeistungszeitraumVon: Date;
    mpNumber: string;
    mpTitle: string;
    Plankosten: number;
    Planpreis: number;
    PoCCV: number;
    projectLead: Employee;
    ProjektGruppierung: string;
    Vorschlpreis: number;
    Verrechnungsart: string;
    WertVerrechnung: number;
    AccountingRelevance: number | string;
    forecastFY: number;
    forecastExternalCostsFY: number;
    plannedExternalCosts: number;
    OrgID: string;
    Verrechnungsland: string;
    IstkostenStunden: number;
    IstpreisStunden: number;
    IstkostenExtern: number;
    IstpreisExtern: number;
    verrechnungszyklus: string;
    businessPartner: BusinessPartner;
    contract: Contract;
    MaterialMaster: MaterialMaster;
    Abgerechnet: boolean;
    OrderPosNr: number;
    accountingElement: AccountingElement;
    OrderQuantity: number;
    pspElement: string;
    partnerDepthStructure: string;
}

export interface ProjectAccountingDataAggregates {
    forecastCostsOverallFY: number;
    forecastCostsExternalFY: number;
    contractVolume: number;
    actualPrice: number;
    actualPriceHours: number;
    actualPriceExternal: number;
    valueToBeSettled: number;
    valueSettled: number;
    valueAllocation: number;
    unfilledOrders: number;
    orderValue: number;
}

export interface ApiAccountingData {
    id: number;
    classificationGrade: string;
    classificationScore: string;
    mpNumber: number;
    mpTitle: string;
    projektGruppierung: string;
    employees?: AccountingEmployee[];
    fsgGesamtprojekt: number;
    verrechnungsart: string;
    auftragswert: number;
    projektName: string;
    projektbeschreibung: string;
    url: string;
    dokumentationUrl: string;
    // schluesselpersonKunde: User;
    projektKategorie: string;
    projektMethode: string;
    lighthouseInnovation: boolean;
    gmsServices: string;
    gmsValueAdd: string;
    task: string;
    technologies: Technology[];
    interfaceificationGrade: string;
    interfaceificationScore: number;
    result: string;
    documents: Document[];
    projectLead: Employee;
    abteilungKunde: string;
    validFrom: string;
    validTo: string;
    location: Location;
    DocumentsId: number[];
    EmployeesId: number[];
    TechnologiesId: number[];
    activity: Activity[];
    originalActivity: Activity[];
    isFavorite: boolean;
    originalIsFavorite: boolean;
    isRemovable: boolean;
    favoriteId: number;
    sortOrder: number;
    originalSortOrder: number;
    singleActivity: Activity;
    planpreis: number;
    program: Program[];
    previousProgram: Program[];
    // businessPartnerPre: string;
    // businessPartnerSur: string;
    restauftragswert: number; // Auftragsbestand ->  "Remaining order value"
    forecastCostsOverallFY: number;
    forecastCostsExternalFY: number;
    projectStatus: string;
    plankosten: number;
    istkosten: number;
    externalCosts: number;
    costCenterInternal: string;
    costCenterNotAvailable: boolean;
    businessPartner: BusinessPartner;
    // businessPartnerId: number;
    startDate: string;
    endDate: string;
    proposedBillingHours?: number;
    valueSettledByHours?: number;
    hoursSettled?: number;
    bestellwert: number;
    orgID: string;
    bestellnummer: string;
    angebotsnummer: number;
    contractNumber: string;
    sdNumber: string;
    verrechnungsland: string;
    abgerechnet: boolean;
    kundenart: string;
    verrechnungszyklus: string;
    invest: boolean;
    auslandskunde: boolean;
    istpreis: number;
    fsgExtern: number;
    approvalTec: boolean;
    approvalCom: boolean;
    accountingRelevance: number | string;
    orderQuantity: number;
    orderPosNr: number;
    materialMaster: MaterialMaster;
    istkostenStunden: number;
    istpreisStunden: number;
    istkostenExtern: number;
    istpreisExtern: number;
    leistungsempfaenger: BusinessArea;
    fsgWert: number;
    abgerechneterWert: number;
    vsp: number;
    spanne: number;
    wertVerrechnung: number;
    abzurechnen: number;
    plannedExternalCosts: number;
    isaStatus: number;
    commercialComment: string;
    projectComment: string;
    multipleBusinessPartner: boolean;
    fSGModified: Date;
    accountingInfo: AccountingInfos[];
    accountingElement: AccountingElement[];
    bestellNummerExistent?: boolean;
    vorschlpreis?: number;
    auftragsbestand?: number;
    pspElement: PspElement;
    partnerDepthStructure: string;
    markedForDeletionTimestamp: Date;
    projectSpaceTitle?: string;
    projectSpaceLink?: string;
    projectType?: ProjectType;
    employee?: AccountingEmployee;
    hasEmployee?: boolean;
}

export interface AccountingDataFrontend {
    id: number;
    classificationGrade: string;
    classificationScore: string;
    mpNumber: number;
    mpTitle: string;
    projektGruppierung: string;
    employees?: Employee[];
    fsgGesamtprojekt: number;
    verrechnungsart: string;
    auftragswert: number;
    projektName: string;
    projektbeschreibung: string;
    url: string;
    dokumentationUrl: string;
    // schluesselpersonKunde: User;
    proposedBillingHours?: number;
    valueSettledByHours?: number;
    hoursSettled?: number;
    projektKategorie: string;
    projektMethode: string;
    lighthouseInnovation: boolean;
    gmsServices: string;
    gmsValueAdd: string;
    task: string;
    technologies: Technology[];
    interfaceificationGrade: string;
    interfaceificationScore: number;
    result: string;
    documents: Document[];
    projectLead: Employee;
    abteilungKunde: string;
    validFrom: string;
    validTo: string;
    location: Location;
    DocumentsId: number[];
    EmployeesId: number[];
    TechnologiesId: number[];
    activity: Activity[];
    originalActivity: Activity[];
    isFavorite: boolean;
    originalIsFavorite: boolean;
    isRemovable: boolean;
    favoriteId: number;
    sortOrder: number;
    originalSortOrder: number;
    singleActivity: Activity;
    planpreis: number;
    program: Program[];
    previousProgram: Program[];
    // businessPartnerPre: string;
    // businessPartnerSur: string;
    restauftragswert: number; // Auftragsbestand ->  "Remaining order value"
    forecastCostsOverallFY: number;
    forecastCostsExternalFY: number;
    projectStatus: string;
    plankosten: number;
    istkosten: number;
    externalCosts: number;
    costCenterInternal: string;
    costCenterNotAvailable: boolean;
    businessPartner: BusinessPartner;
    // businessPartnerId: number;
    startDate: Date;
    endDate: Date;
    bestellwert: number;
    orgID: string;
    bestellnummer: string;
    angebotsnummer: number;
    contractNumber: string;
    sdNumber: string;
    verrechnungsland: string;
    abgerechnet: boolean;
    kundenart: string;
    verrechnungszyklus: string;
    invest: boolean;
    auslandskunde: boolean;
    istpreis: number;
    fsgExtern: number;
    approvalTec: boolean;
    approvalCom: boolean;
    accountingRelevance: number | string;
    orderQuantity: number;
    orderPosNr: number;
    materialMaster: MaterialMaster;
    istkostenStunden: number;
    istpreisStunden: number;
    istkostenExtern: number;
    istpreisExtern: number;
    leistungsempfaenger: BusinessArea;
    fsgWert: number;
    abgerechneterWert: number;
    vsp: number;
    spanne: number;
    wertVerrechnung: number;
    abzurechnen: number;
    plannedExternalCosts: number;
    isaStatus: number;
    commercialComment: string;
    projectComment: string;
    multipleBusinessPartner: boolean;
    fSGModified: Date;
    accountingInfo: AccountingInfos[];
    accountingElement: AccountingElement;
    bestellNummerExistent?: boolean;
    vorschlpreis?: number;
    auftragsbestand?: number;
    rowType?: number;
    orderValueSum?: number;
    pspElement: PspElement;
    partnerDepthStructure: string;
    markedForDeletionTimestamp: Date;
    projectSpaceTitle?: string;
    projectSpaceLink?: string;
    projectType?: ProjectType;
}

export interface AccountingDataWithEmployeeFrontend extends AccountingDataFrontend {
    hasEmployee?: boolean;
    employee?: AccountingEmployee;
}

export interface AccountingDistribute {
    id: number;
    accounted: boolean;
    accountedSAP: number;
    hasApprovedElements: boolean;
    hasBillingJournals: boolean;
    materialNumber: MaterialMaster;
    orderPosNumber: string;
    orderQuantity: number;
    pricePerUnit: number;
    project: {
        id: number;
        hasDetails: boolean;
        mpTitle: string;
        projectName: string;
    };
}
