import { CeresFeaturesConfig } from './interfaces/ceres-features-config.interface';

const FEATURES: CeresFeaturesConfig = {
    timeManagement: {
        enabled: true,
        timeSheets: true,
        transactionalTimeTracking: false,
        capacity: true,
        absences: true,
        workload: true,
        disableTimeSheetsOption: false
    },
    businessPartner: {
        enabled: true
    },
    sales: {
        enabled: true,
        businessOpportunities: true,
        salesActivities: false,
        salesFunnel: false,
        offers: false,
        offersV2: false
    },
    projects: {
        enabled: true,
        copyExistingProject: false,
        orcChecker: false,
        inheritance: true,
        groups: true,
        charging: false,
        nrsView: false,
        preStaffing: true,
        projectValueInheritance: false
    },
    reporting: {
        enabled: true,
        dataQuality: false,
        pulseDashboard: false,
        costReport: false,
        workingHoursReport: true,
        gmsReportingDemo: false,
        productivityReport: false
    },
    employees: {
        enabled: true,
        skills: true,
        multiRole: true
    },
    faq: {
        enabled: false
    },
    ticketTool: {
        enabled: true
    },
    administration: {
        enabled: true,
        servicePackages: false,
        departments: false,
        serviceProducts: false,
        orgIds: false,
        translations: true,
        skillMatrix: true,
        juno: true,
        customerPspElements: false
    },
    services: {
        enabled: false
    },
    global: {
        currencyConversion: false,
        darkMode: true
    }
};

export default FEATURES;
