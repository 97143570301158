import { Injectable } from '@angular/core';
import {
    Capacity,
    CapacityDeleteRequest,
    CapacityDeleteResponse,
    CapacityGetBaseRequest,
    CapacityGetFiltersResponse,
    CapacityGetRequest,
    CapacityGetTargetHoursRequest,
    CapacityPutRequest,
    CapacityTypeEnum,
    PaginationCapacityGetResponse
} from '@ceres/domain';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { HttpParams } from '@angular/common/http';
import { format } from 'date-fns';
import { environment } from '../../../environments/environment';
import { Month } from '../models';
import { DateService } from './date.service';

@Injectable()
export class CapacityService {
    constructor(private http: ImpersonatedHttpClient) {}

    public getByUserID(id: number, month: Month): Promise<Capacity[]> {
        const firstWeek = month.CalendarWeeks[0].weekNo.toString();
        const lastWeek = month.CalendarWeeks[month.CalendarWeeks.length - 1].weekNo.toString();
        const year = month.Date.getFullYear().toString();

        return this.http
            .get<
                Capacity[]
            >(`${environment.edgeService}/capacities/employees/${id}`, { params: { firstWeek, lastWeek, year } })
            .toPromise();
    }

    public getByUserIDV2(
        id: number,
        date: Date,
        capacityType: CapacityTypeEnum
    ): Promise<PaginationCapacityGetResponse> {
        const [startDate, endDate] = DateService.getStartEndDateInterval(date, capacityType);
        const request: CapacityGetRequest = {
            capacityType,
            employeeId: id,
            from: startDate,
            to: endDate
        };

        const params = new HttpParams()
            .set('employeeId', request.employeeId.toString())
            .set('from', format(request.from, 'yyyy-MM-dd'))
            .set('to', format(request.to, 'yyyy-MM-dd'))
            .set('capacityType', request.capacityType);

        return this.http
            .get<PaginationCapacityGetResponse>(`${environment.edgeService}/capacities/v2`, {
                params
            })
            .toPromise();
    }

    public getFiltersV2(id: number, month: Month): Promise<CapacityGetFiltersResponse> {
        const request: CapacityGetBaseRequest = {
            employeeId: id,
            from: month.CalendarWeeks[0].first.date,
            to: month.CalendarWeeks[month.CalendarWeeks.length - 1].last.date
        };

        const params = new HttpParams()
            .set('employeeId', request.employeeId.toString())
            .set('from', format(request.from, 'yyyy-MM-dd'))
            .set('to', format(request.to, 'yyyy-MM-dd'));

        return this.http
            .get<CapacityGetFiltersResponse>(`${environment.edgeService}/capacities/v2/filters`, {
                params
            })
            .toPromise();
    }

    public getCapacitiesWithEmployee(month: Month): Promise<Capacity[]> {
        const firstWeek = month.CalendarWeeks[0].weekNo;
        let lastWeek = month.CalendarWeeks[month.CalendarWeeks.length - 1].weekNo;
        if (lastWeek < firstWeek) {
            lastWeek = 54;
        }
        return this.http
            .get<Capacity[]>(`${environment.edgeService}/capacities`, {
                params: {
                    firstWeek: firstWeek.toString(),
                    lastWeek: lastWeek.toString(),
                    employee: 'true'
                }
            })
            .toPromise();
    }

    /* public getCapacitiesByIdsQuery(
    ids: number[],
    month: Month
  ): Promise<Capacity[]> {
    const id = ids.join(',');
    const firstWeek = month.CalendarWeeks[0].weekNo;
    let lastWeek = month.CalendarWeeks[month.CalendarWeeks.length - 1].weekNo;
    if (lastWeek < firstWeek) {
      lastWeek = 54;
    }
    return this.http
      .get<Capacity[]>(`${environment.edgeService}/capacities`, {
        params: {
          firstWeek: firstWeek.toString(),
          lastWeek: lastWeek.toString(),
          id
        }
      })
      .toPromise();
  }*/

    public getByMPNumber(id: number, month: Month): Promise<Capacity[]> {
        const firstWeek = month.CalendarWeeks[0].weekNo.toString();
        let lastWeek = '';
        // The first two calendar weeks in January might have both weekNo 1, so we have to correct the weekNo of the last week
        if (month.CalendarWeeks[0].weekNo === month.CalendarWeeks[1].weekNo && month.TitleEN.includes('January')) {
            lastWeek = (month.CalendarWeeks[month.CalendarWeeks.length - 1].weekNo + 1).toString();
        } else {
            // If the last week continues into next year, its weekNo is 1 in the database
            lastWeek = month.CalendarWeeks[month.CalendarWeeks.length - 1].weekNo.toString();
            if (lastWeek === '53') {
                lastWeek = '1';
            }
        }
        const year = month.Date.getFullYear();

        return this.http
            .get<Capacity[]>(`${environment.edgeService}/capacities/mp/${id}`, {
                params: { firstWeek, lastWeek, year: `${year}` }
            })
            .toPromise();
    }

    public upsertManyV2(body: CapacityPutRequest) {
        return this.http.put(`${environment.edgeService}/capacities/v2`, body);
    }

    public deleteV2(params: CapacityDeleteRequest) {
        const httpParams = new HttpParams()
            .set('employeeId', params.employeeId.toString())
            .set('entries', JSON.stringify(params.entries));

        return this.http.delete<CapacityDeleteResponse>(`${environment.edgeService}/capacities/v2`, {
            params: httpParams
        });
    }

    public getEmployeeTargetHoursForUnitType(
        id: number,
        from: Date,
        to: Date,
        capacityType: CapacityTypeEnum
    ): Promise<Record<string, number>> {
        const request: CapacityGetTargetHoursRequest = {
            employeeId: id,
            from,
            to,
            capacityType
        };

        const params = new HttpParams()
            .set('employeeId', request.employeeId.toString())
            .set('from', format(request.from, DateService.DB_DATE_FORMAT))
            .set('to', format(request.to, DateService.DB_DATE_FORMAT))
            .set('capacityType', request.capacityType);

        return this.http
            .get<Record<string, number>>(`${environment.edgeService}/capacities/employee/targetHours`, {
                params
            })
            .toPromise();
    }
}
