import { Injectable } from '@angular/core';
import { FilterService, DateFilter } from '@ceres/filter';
import { ProjectProfileDto as ProjectProfile } from '@ceres/domain';

@Injectable()
export class ProjectRelationFilterService extends FilterService<ProjectProfile> {
    public globalFilters = [new DateFilter('startDate'), new DateFilter('endDate')];

    constructor() {
        super();
    }

    applyFiscalYear({ date, dateEnd }: { date: Date; dateEnd: Date }) {
        this.globalFilters
            .filter(({ key }) => key === 'startDate' || key === 'endDate')
            .forEach((filter) => {
                filter.start = date;
                filter.end = dateEnd;
                filter.isApplied = filter.isActive();
            });
        this.triggerFilter();
    }
}
