import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ProjectProfile } from '@ceres/domain';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ListItem } from '@ceres/frontend-helper';
import { ProjectRelationsSelectService } from '../../../services/project-relations-select.service';
import { ProjectService } from '../../../services/project.service';
import { ProjectRelationFilterService } from '../../../services/project-relation-filter.service';
import { FilterFactoryService } from '../../../../shared/services/filter-factory.service';

@Component({
    selector: 'ceres-project-relations-select',
    templateUrl: './project-relations-select.component.html',
    styleUrls: ['./project-relations-select.component.scss']
})
export class ProjectRelationsSelectComponent implements OnInit, OnDestroy {
    public availableColumns: ListItem[] = [
        { title: 'selected', selected: true },
        { title: 'mpNumber', selected: true },
        { title: 'mpTitle', selected: true },
        { title: 'projektGruppierung', selected: true },
        { title: 'projectLead.name', selected: true },
        { title: 'businessPartner.fullName', selected: true }
    ];

    public displayedColumns: string[] = [];

    public filters = {};

    public searchControl = new FormControl('');

    public gridWidth = 0;

    public projects: ProjectProfile[] = [];
    public selectedProjects = new Map<number, ProjectProfile>();
    public multiple = false;
    private parentId = null;
    public allChecked = false;
    public fewSelected = false;

    private filterNames = ['mpNumber', 'projektGruppierung', 'projectLead.name', 'businessPartner.fullName'];

    private columnWidth = 140;

    private destroy$: Subject<boolean> = new Subject<boolean>();

    @ViewChild(MatSort)
    sort: MatSort;

    constructor(
        public dialogRef: MatDialogRef<ProjectRelationsSelectComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public projectSelectionService: ProjectRelationsSelectService,
        private filterFactory: FilterFactoryService,
        private projectService: ProjectService,
        readonly filterService: ProjectRelationFilterService
    ) {
        if (this.data.multiple) {
            this.multiple = this.data.multiple;
        }
        if (this.data.projects && this.data.projects.length) {
            this.selectedProjects = this.data.projects.filter((e) => e.id);
        }
        if (this.data.parentId) {
            this.parentId = this.data.parentId;
        }
    }

    ngOnInit() {
        this.filters = this.filterFactory.create(this.filterNames);
        this.projectSelectionService.getFilterValues();
        const initialFiscalYear = this.projectService.allFiscalYears[1];
        initialFiscalYear.dateEnd = this.projectService.allFiscalYears[2].dateEnd;
        this.filterService.applyFiscalYear(initialFiscalYear);
        this.changeDisplayedColumns(this.availableColumns);
        // TODO andere Lösung finden, da DOM-Manipulationen in Angular verboten
        document.querySelector('body').classList.add('new-wrapper');

        this.subscribeToFilterChanges();
        this.subscribeToAppliedFilters();
        this.subscribeToSearchChanges();
        this.subscribeToProjectProfileChanges();

        if (this.parentId) {
            this.filterDepartment();
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.projects = [];
        // TODO andere Lösung finden, da DOM-Manipulationen in Angular verboten
        document.querySelector('body').classList.remove('new-wrapper');
    }

    public pageChanged(event: PageEvent) {
        const { pageIndex, pageSize } = { ...event };
        this.projectSelectionService.setPage({ pageIndex, pageSize });
    }

    public sortChanged(event: Sort) {
        if (event && event.active && event.direction) {
            this.projectSelectionService.setSort({
                key: event.active,
                direction: event.direction
            });
        } else {
            this.projectSelectionService.setSort(null);
        }
    }

    public changeSelected(element: ProjectProfile, event) {
        this.selectedProjects = new Map(this.selectedProjects);
        if (!this.multiple) {
            if (event.checked) {
                this.selectedProjects.clear();
                this.selectedProjects.set(element.mpNumber, element);
            } else {
                this.selectedProjects.clear();
            }
        } else {
            if (event.checked) {
                this.selectedProjects.set(element.mpNumber, element);
            } else {
                this.selectedProjects.delete(element.mpNumber);
            }
        }
        this.checkIfAllAreSelected();
    }

    public filterDepartment() {
        this.projectService.getProjectProfile(this.parentId).subscribe((parentProject: ProjectProfile) => {
            if (parentProject.kundenart === 'GMS Intern') {
                const department = parentProject.projektGruppierung.split('-').join(' ');
                this.projectSelectionService.setGMSInternal(true, department);
            } else {
                this.projectSelectionService.setGMSInternal(false);
            }
        });
    }

    public selectAll() {
        this.selectedProjects = new Map(this.selectedProjects);
        if (this.allChecked || this.fewSelected) {
            const projectProfiles = this.projectSelectionService.getAllProjectProfiles();
            for (const profile of projectProfiles) {
                this.selectedProjects.set(profile.mpNumber, profile);
            }
            this.fewSelected = false;
            this.allChecked = true;
        } else if (!this.allChecked) {
            this.selectedProjects.clear();
        }
    }

    public remove(element: ProjectProfile) {
        this.changeSelected(element, { checked: false });
    }

    public closeDialog() {
        if (!this.selectedProjects || this.selectedProjects.size === 0) {
            this.dialogRef.close();
        } else {
            const selected = [...this.selectedProjects.values()];
            if (!this.multiple) {
                if (selected.length > 0) {
                    this.dialogRef.close({ ...selected[0] });
                } else {
                    this.dialogRef.close();
                }
            } else {
                this.dialogRef.close(selected);
            }
        }
    }

    public changeDisplayedColumns(columns: ListItem[]) {
        this.displayedColumns = columns.filter((column) => column.selected).map((column) => column.title);
        this.gridWidth = this.displayedColumns.length * this.columnWidth;
    }

    private checkIfAllAreSelected() {
        const projectProfiles = this.projectSelectionService.getAllProjectProfiles();
        const allSelected = projectProfiles.reduce(
            (agg, profile) => agg && this.selectedProjects.has(profile.mpNumber),
            true
        );

        if (allSelected) {
            this.allChecked = true;
            this.fewSelected = false;
        } else {
            this.fewSelected = this.selectedProjects.size > 0;
            this.allChecked = false;
        }
    }

    private subscribeToFilterChanges() {
        this.projectSelectionService.filterValues$
            .pipe(takeUntil(this.destroy$))
            .subscribe((filters) => (this.filterService.fixedFilterValues = filters));
    }

    private subscribeToAppliedFilters() {
        this.filterService.appliedFilters$.pipe(takeUntil(this.destroy$)).subscribe(
            (filters) => (this.projectSelectionService.appliedFilters = filters) // TODO move to filterService
        );
    }

    private subscribeToSearchChanges() {
        this.searchControl.valueChanges
            .pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(900))
            .subscribe((search) => (this.projectSelectionService.search = search)); // TODO move to filterService
    }

    private subscribeToProjectProfileChanges() {
        this.projectSelectionService.projectProfiles$.pipe(takeUntil(this.destroy$)).subscribe((projectProfiles) => {
            if (this.data.projectTreeIds) {
                this.projects = projectProfiles.filter((value) => !this.data.projectTreeIds.includes(value.id));
            }
            this.checkIfAllAreSelected();
        });
    }
}
