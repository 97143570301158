import { Injectable } from '@angular/core';
import { CommonPagination, CommonLoading } from '@ceres/frontend-helper';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ProjectProfile, ProjectProfileFilterValues, PaginationResponse } from '@ceres/domain';
import { Filter, MultiValueFilter } from '@ceres/filter';
import { HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { AppUserService } from '@ceres/shared/services';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectRelationsSelectService extends CommonPagination({ index: 0, size: 15 }, CommonLoading(Object)) {
    private _projectProfiles: BehaviorSubject<ProjectProfile[]> = new BehaviorSubject<ProjectProfile[]>([]);
    public projectProfiles$: Observable<ProjectProfile[]> = this._projectProfiles.asObservable();

    private _filterValues: BehaviorSubject<ProjectProfileFilterValues> =
        new BehaviorSubject<ProjectProfileFilterValues>(null);
    public filterValues$: Observable<ProjectProfileFilterValues> = this._filterValues.asObservable();

    private _appliedFilters: Filter[] = [];

    private _search = '';
    private gmsInternalProject = false;
    private department = null;

    constructor(
        private http: ImpersonatedHttpClient,
        private appUserService: AppUserService
    ) {
        super();
    }

    public set appliedFilters(appliedFilters: Filter[]) {
        this._appliedFilters = appliedFilters;
        this.getAll(); // TODO duplicated calls needs to be refactored inanother task
    }

    public get appliedFilters(): Filter[] {
        return this._appliedFilters;
    }

    public setGMSInternal(internal: boolean, department?: string) {
        this.gmsInternalProject = internal;
        if (this.gmsInternalProject) {
            this.department = department;
        }
        this.getAll();
    }

    public set search(search: string) {
        this._search = search;
        this.pageIndex = 0;
        this.getAll();
    }

    public get search(): string {
        return this._search;
    }

    public getAllProjectProfiles(): ProjectProfile[] {
        return this._projectProfiles.getValue();
    }

    public getAll() {
        this.loading = true;
        let params = this.getPaginationParams();
        const filters: any[] = [];

        this.appliedFilters.map((filter) => {
            filters.push(filter);
        });

        if (this.gmsInternalProject) {
            filters.push({
                key: 'businessPartner.department',
                type: 'search',
                term: this.department
            });

            filters.push({
                key: 'businessPartner.gpType',
                type: 'value',
                value: 'GMS'
            });
        }

        if (this.search && this.search !== '') {
            const searchFields = [
                'mpNumber::text',
                'mpTitle',
                'projektGruppierung',
                'projectLead.name',
                'businessPartner.fullName'
            ];
            searchFields.map((field) =>
                filters.push({
                    key: field,
                    type: 'search',
                    term: this.search
                })
            );
        }

        filters.push(
            new MultiValueFilter('projectStatus', [
                'projects.project-property-options.status.in-progress',
                'projects.project-property-options.status.other'
            ])
        );

        if (filters.length > 0) {
            params = params.append('filters', JSON.stringify(filters));
        }
        const url = '/projects/profilesMissingLink';

        this.http
            .get<PaginationResponse<ProjectProfile>>(`${environment.edgeService}${url}`, {
                params
            })
            .pipe(catchError(() => of({ data: [], total: 0 })))
            .subscribe(({ data, total }) => {
                if (this.gmsInternalProject) {
                    void this.getAllExternalProjects().then((externalProjects) => {
                        this._total.next(externalProjects.total + total);
                        this._projectProfiles.next([...externalProjects.data, ...data]);
                        this.loading = false;
                    });
                } else {
                    this._total.next(total);
                    this._projectProfiles.next(data);
                    this.loading = false;
                }
            });
    }

    private async getAllExternalProjects() {
        const url = '/projects/profilesMissingLink';
        let params = this.getPaginationParams();
        const filters = [];

        if (this.search && this.search !== '') {
            const searchFields = [
                'mpNumber::text',
                'mpTitle',
                'projektGruppierung',
                'projectLead.name',
                'businessPartner.fullName'
            ];
            searchFields.map((field) =>
                filters.push({
                    key: field,
                    type: 'search',
                    term: this.search
                })
            );
        }
        filters.push(
            new MultiValueFilter('projectStatus', [
                'projects.project-property-options.status.in-progress',
                'projects.project-property-options.status.other'
            ])
        );
        if (filters.length > 0) {
            params = params.append('filters', JSON.stringify(filters));
        }

        const response = await this.http
            .get<PaginationResponse<ProjectProfile>>(`${environment.edgeService}${url}`, { params })
            .toPromise();
        const data = response.data.filter((data) => data.kundenart !== 'GMS Intern');
        return { total: data.length, data };
    }

    public getFilterValues() {
        const employee = this.appUserService.loggedInUser$.getValue();
        const projectGrouping = employee.details.businessArea.title;
        const projectLeadName = employee.name;
        let params = new HttpParams();
        params = params.append('projectGrouping', projectGrouping);
        params = params.append('projectLeadName', projectLeadName);
        this.http
            .get<ProjectProfileFilterValues>(
                `${environment.edgeService}/projects/employees/${employee.id}/filterValues`,
                { params }
            )
            .subscribe((filterValues) => this._filterValues.next(filterValues));
    }
}
