import { Employee } from './employee';
import { AdjacentInfo } from './project-adjacent-info';
import { ProjectProfile } from './project-profile';

export interface ProjectTree
    extends Pick<
        ProjectProfile,
        | 'id'
        | 'mpTitle'
        | 'projektName'
        | 'startDate'
        | 'endDate'
        | 'projectType'
        | 'verrechnungsart'
        | 'plannedHours'
        | 'actualHours'
        | 'abgerechneterWert'
        | 'actualCostsHours'
        | 'actualPrice'
        | 'actualCostsExternal'
        | 'bestellwert'
        | 'parentProjectId'
    > {
    employees: Pick<Employee, 'id'>[];
    adjacence: AdjacentInfo;
    fullAccess: boolean;
}
