<ng-container *ngIf="!multiple; else multipleAlternative">
  <mat-form-field class="mat-form-field mat-custom w-100 picker" appearance="fill">
    <input matInput [required]="required" placeholder="{{ placeholderTranslationKey | transloco }}" [value]="getProjectInputValue()" readonly>
    <ng-container *ngIf="!readonly">
      <ceres-button
        *ngIf="!projects.length; else other"
        (click)="openProjectSelection()"
        [layout]="'picker'">
        <i class="material-icons">add</i>
      </ceres-button>
      <ng-template #other>
        <ceres-button
          (click)="removeSelected()"
          [layout]="'picker'">
          <i class="material-icons">delete</i>
        </ceres-button>
      </ng-template>
    </ng-container>
  </mat-form-field>
</ng-container>
<ng-template #multipleAlternative>
  <mat-form-field class="w-100">
    <mat-chip-list #chipList>
      <mat-chip (removed)="removeSelected(project)" *ngFor="let project of projects">
        {{ project.mpTitle }}
        <mat-icon *ngIf="!readonly" matChipRemove>cancel</mat-icon>
      </mat-chip>
      <mat-chip (click)="openProjectSelection()" *ngIf="!readonly">
        <mat-icon>add</mat-icon>
      </mat-chip>
      <input [disabled]="readonly" [matChipInputFor]="chipList" placeholder="{{ placeholderTranslationKey || transloco }}" readonly>
    </mat-chip-list>
  </mat-form-field>
</ng-template>
