export interface FinancialCalculations {
    id: number;
    name: string;
    projectTypeName: string;
    actualPrice: number;
    actualPriceHours: number;
    actualPriceExternal: number;
    actualCosts: number;
    actualCostsHours: number;
    actualCostsExternal: number;
    accruedCostsExternal: number;
    actualProfit: number;
    pocValue: number;
    pocProfit: number;
    valueSettled: number;
    valueToBeSettled: number;
    remainingBudget: number;
    linearForecast: number;
    proportionalLinearRemainingBudget: number;
    hoursSettled: number;
    valueSettledByHours: number;
    proposedBillingHours: number;
    proposedBillingValueHours: number;
    orderValue: number;
    contractVolume: number;
}

export interface FinancialCalculationOverview {
    overall: Omit<FinancialCalculations, 'id' | 'name' | 'projectTypeName'>;
    detail: FinancialCalculations[];
}
