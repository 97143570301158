import { FeatureToggleConfig } from '@ceres/feature-toggles';

export interface CeresFeaturesConfig extends FeatureToggleConfig {
    timeManagement: {
        enabled: boolean;
        timeSheets: boolean;
        transactionalTimeTracking: boolean;
        capacity: boolean;
        absences: boolean;
        workload: boolean;
        disableTimeSheetsOption: boolean;
    };
    businessPartner: {
        enabled: boolean;
    };
    sales: {
        enabled: boolean;
        businessOpportunities: boolean;
        salesActivities: boolean;
        salesFunnel: boolean;
        offers: boolean;
        offersV2: boolean;
    };
    projects: {
        enabled: boolean;
        copyExistingProject: boolean;
        orcChecker: boolean;
        inheritance: boolean;
        groups: boolean;
        charging: boolean;
        nrsView: boolean;
        preStaffing: boolean;
        projectValueInheritance: boolean;
    };
    reporting: {
        enabled: boolean;
        dataQuality: boolean;
        pulseDashboard: boolean;
        costReport: boolean;
        workingHoursReport: boolean;
        gmsReportingDemo: boolean;
        productivityReport: boolean;
    };
    employees: {
        enabled: boolean;
        skills: boolean;
        multiRole: boolean;
    };
    faq: {
        enabled: boolean;
    };
    ticketTool: {
        enabled: boolean;
    };
    administration: {
        enabled: boolean;
        servicePackages: boolean;
        departments: boolean;
        serviceProducts: boolean;
        orgIds: boolean;
        translations: boolean;
        skillMatrix: boolean;
        juno: boolean;
        customerPspElements: boolean;
    };
    services: {
        enabled: boolean;
    };
    global: {
        currencyConversion: boolean;
        darkMode: boolean;
    };
}
