import { Injectable } from '@angular/core';
import { CommonPagination, CommonLoading } from '@ceres/frontend-helper';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import {
    ProjectProfile,
    PaginationResponse,
    GetProjectsByFilterWithMissingOfferResponse,
    DIFA_DEV,
    DIFA,
    FiscalYearExport,
    ProjectTree,
    AdjacentInfo
} from '@ceres/domain';
import { Filter } from '@ceres/filter';
import { HttpParams } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { XlsxExportService } from '@ceres/shared/services';
import { TranslocoService } from '@ngneat/transloco';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { format } from 'date-fns';
import { environment } from '../../../environments/environment';
import { DateService } from '../../shared/services/date.service';
import { ProjectServicesModule } from './project-services.module';

type FiscalYear = { date: Date; dateEnd: Date; label: string };

@Injectable({
    providedIn: ProjectServicesModule
})
export class ProjectService extends CommonPagination({ index: 0, size: 15 }, CommonLoading(Object)) {
    private _projectProfiles: BehaviorSubject<ProjectProfile[]> = new BehaviorSubject<ProjectProfile[]>([]);
    public projectProfiles$: Observable<ProjectProfile[]> = this._projectProfiles.asObservable();

    public allFiscalYears: FiscalYear[];

    private _appliedFilters: Filter[] = [];

    constructor(
        private http: ImpersonatedHttpClient,
        private dateService: DateService,
        private xlsxExportService: XlsxExportService,
        private translateService: TranslocoService
    ) {
        super();
        this.allFiscalYears = this.dateService.getBusinessYears();
    }

    public set appliedFilters(appliedFilters: Filter[]) {
        this._appliedFilters = appliedFilters;
        this.pageIndex = 0;
        this.getAll();
    }

    public get appliedFilters(): Filter[] {
        return this._appliedFilters;
    }

    public getProjectAdjacentInfo(projectId: number) {
        return this.http.get<AdjacentInfo>(`${environment.edgeService}/projects/projectAdjacentInfo/${projectId}`);
    }

    public getProjectTree(projectId: number) {
        return this.http.get<ProjectTree[]>(`${environment.edgeService}/projects/projectTree/${projectId}`);
    }

    public getMissingLinkProjects() {
        return this.http.get(`${environment.edgeService}/projects/profilesMissingLink`);
    }

    public getAccountingDistribute(projectId: number) {
        return this.http.get(`${environment.edgeService}/projects/${projectId}/accounting/distribute`);
    }

    public getMaterials() {
        return this.http.get(`${environment.edgeService}/projects/profiles/materials`);
    }

    public getProjectProfile(projectId: number) {
        return this.http.get(`${environment.edgeService}/projects/profiles/${projectId}`);
    }

    public getProjectProfileFinancialOverview(projectId: number) {
        return this.http.get(`${environment.edgeService}/projects/financials/overview/${projectId}`);
    }

    public setParent(projectId: number, parentId: number) {
        return this.http.put(`${environment.edgeService}/projects/setParent/${projectId}`, {
            parentId
        });
    }

    public putAccountingDistribute(data) {
        return this.http.put(`${environment.edgeService}/projects/accounting/infos/distribute`, data);
    }

    public getAll() {
        this.loading = true;
        let params = this.getPaginationParams();
        params = this.appendFilters(params);

        this.http
            .get<PaginationResponse<ProjectProfile>>(`${environment.edgeService}/projects/profiles`, {
                params
            })
            .pipe(
                catchError(() => of({ data: [] as ProjectProfile[], total: 0 })),
                map(({ data, total }) => {
                    const results = this.prepareProjectData(data);
                    return { total, data: results };
                })
            )
            .subscribe(({ data, total }) => {
                this._total.next(total);
                this._projectProfiles.next(data);
                this.loading = false;
            });
    }

    public async exportProjects(displayedColumns: string[]): Promise<any> {
        const data = await this.fetchAndCombineDailyData();

        if (typeof data === 'boolean') {
            return true;
        }

        return this.xlsxExportService.genericExport(
            this.translateService.translate('project.list.excel.file'),
            data,
            displayedColumns,
            ''
        );
    }

    private getAllProjects(pageIndex: number, pageSize: number): Promise<ProjectProfile[]> {
        let params = this.setPaginationParams(pageIndex, pageSize);
        params = this.appendFilters(params);

        return this.http
            .get<PaginationResponse<ProjectProfile>>(`${environment.edgeService}/projects/profiles`, {
                params
            })
            .pipe(
                catchError(() => of({ data: [] as ProjectProfile[], total: 0 })),
                map(({ data }) =>
                    this.prepareProjectData(data).map((element) => {
                        this.translateForExcellExport(element);
                        return element;
                    })
                )
            )
            .toPromise();
    }

    private async fetchAndCombineDailyData(): Promise<ProjectProfile[] | boolean> {
        const pageSize = this.appliedFilters.some((filter) => filter.key === 'projektGruppierung') ? 50 : 300;
        let totalNumberOfData: number = this._total.value;

        try {
            if (!totalNumberOfData) {
                totalNumberOfData = await this.getProjectsTotal();
            }
        } catch (e) {
            return true;
        }

        const iterations = Math.ceil(totalNumberOfData / pageSize);

        const results = [];
        for (let index = 0; index < iterations; index++) {
            const result = await this.getAllProjects(index, pageSize);
            results.push(result);
        }
        return results.reduce((acc, result) => acc.concat(result), []);
    }

    /**
     * Gets the amount of the data that should be exported for the projects.
     * @returns number of the rows in the Excel table, that should be displayed
     * @throws an Error if the amount of data is 0 or if some Error happened in the backend
     */
    private async getProjectsTotal(): Promise<number> {
        try {
            let params = new HttpParams();
            params = this.appendFilters(params);

            const result: number = await this.http
                .get<number>(`${environment.edgeService}/projects/profiles-total`, {
                    params
                })
                .toPromise();

            if (result === 0) {
                throw new Error();
            }
            return result;
        } catch (e) {
            return Promise.reject();
        }
    }

    private translateForExcellExport(element: ProjectProfile) {
        element.verrechnungsart = this.translateService.translate(
            `projects.project-property-options.accounting-type.${element.verrechnungsart}`
        );
        element.verrechnungszyklus = this.translateService.translate(
            `projects.project-property-options.accounting-cycle.${element.verrechnungszyklus}`
        );
        element.projectStatus = this.translateService.translate(element.projectStatus);
        const tagArray = element.projectTags
            ? element.projectTags.map((tag) => this.translateService.translate(tag.translationKey))
            : ['-'];
        const tagString = tagArray.join(', ');
        Object.assign(element, { projectTagsForExport: tagString });
    }

    public exportProjectsWithMissingOrderNumber(): Observable<any> {
        let params = new HttpParams();
        params = this.appendFilters(params);

        let displayedColumns = [
            'id',
            'mpNumber',
            'mpTitle',
            'businessPartner.salutation',
            'businessPartner.firstName',
            'businessPartner.lastName',
            'businessPartner.email',
            'businessPartner.department',
            'businessPartner.gid',
            'projectLead.name',
            'projectLead.email',
            'projectLead.gid',
            'offerTitle',
            'offerNumber',
            'offerId'
        ];

        if (environment.tenant === DIFA_DEV || environment.tenant === DIFA) {
            displayedColumns = displayedColumns.concat('orgId', 'sdNumber', 'orderNumber');
        }

        return this.http
            .get<PaginationResponse<GetProjectsByFilterWithMissingOfferResponse>>(
                `${environment.edgeService}/projects/profilesMissingOffer`,
                {
                    params
                }
            )
            .pipe(
                catchError(() =>
                    of({
                        data: [] as GetProjectsByFilterWithMissingOfferResponse[],
                        total: 0
                    })
                ),
                map(({ data }) => data),
                switchMap(
                    (data: GetProjectsByFilterWithMissingOfferResponse[]): Observable<boolean | void> =>
                        data.length === 0
                            ? of(true)
                            : from(
                                  this.xlsxExportService.genericExport(
                                      this.translateService.translate('project.list.excel.file'),
                                      data,
                                      displayedColumns
                                  )
                              )
                )
            );
    }

    public fiscalYearExport(currentFiscalYear: {
        date: Date;
        dateEnd: Date;
        label: string;
    }): Observable<boolean | void> {
        const params = new HttpParams()
            .set('currentFiscalYearStartDate', format(currentFiscalYear.date, 'yyyy-MM-dd'))
            .set('currentFiscalYearEndDate', format(currentFiscalYear.dateEnd, 'yyyy-MM-dd'));

        const displayedColumns = [
            'costCenterDepartment',
            'additionalActivityDescription',
            'projectPspElement',
            'workingHoursSum'
        ];

        const columnsToAdd = [
            { key: 'ME', index: displayedColumns.indexOf('workingHoursSum') + 1 },
            { key: 'PersNr', index: displayedColumns.indexOf('workingHoursSum') + 2 },
            { key: 'Text', index: displayedColumns.indexOf('workingHoursSum') + 3 }
        ];

        return this.http
            .get<FiscalYearExport[]>(`${environment.edgeService}/time-sheets/fiscal-year-export`, { params })
            .pipe(
                catchError(() => of([])),
                switchMap(
                    (data: FiscalYearExport[]): Observable<boolean | void> =>
                        data.length === 0
                            ? of(true)
                            : from(
                                  this.xlsxExportService.genericExport(
                                      this.translateService.translate('project.list.excel.fiscalYearFile', {
                                          fiscalYear: currentFiscalYear.label,
                                          currentDate: format(new Date(), 'yyyy-MM-dd')
                                      }),
                                      data,
                                      displayedColumns,
                                      '',
                                      columnsToAdd
                                  )
                              )
                )
            );
    }

    private appendFilters(params: HttpParams) {
        if (this.appliedFilters.length > 0) {
            return params.append('filters', JSON.stringify(this.appliedFilters));
        }
        return params;
    }

    private calculateDays() {
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
        return { firstDay, lastDay };
    }

    private prepareProjectData(data: ProjectProfile[]) {
        const { firstDay, lastDay } = this.calculateDays();
        return data.map((element) => {
            if (!element.validFrom) {
                element.validFrom = firstDay;
            }
            if (!element.validTo) {
                element.validTo = lastDay;
            }
            if (!element.istpreisExtern) {
                element.istpreisExtern = 0;
            }

            for (const elem in element) {
                if (!isNaN(element[elem])) {
                    element[elem] = element[elem] ? +(+element[elem]).toFixed(2) : 0;
                }
            }
            return element;
        });
    }

    private setPaginationParams(pageIndex: number, pageSize: number): HttpParams {
        let params = new HttpParams();
        params = params.append('page', `${pageIndex}`);
        params = params.append('size', `${pageSize}`);
        if (this.pageSort && this.pageSort.key && this.pageSort.direction) {
            params = params.append('sort', `${this.pageSort.key},${this.pageSort.direction.toUpperCase()}`);
        }
        return params;
    }
}
