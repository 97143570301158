export interface ChargingEmployeeList {
    employeeGid: string;
    employeeContractType: string;
    costCenterInternal: string;
    customerType: string;
    workingHoursForWorkloadIds: string;
    sumHours: number;
    location: string;
    activityName: string;
    workloadIds: string;
}
